import React, { FC } from 'react'

import { LinkProps } from '../../atoms/Link'

import * as SC from './styled'

export type ListTagLinksProps = MinimalSeoBlock & {
  list?: { linkProps?: LinkProps; count?: number; active?: boolean }[]
}

const ListTagLinks: FC<ListTagLinksProps> = ({ list, ...className }) => {
  return (
    <SC.Container {...className}>
      {list?.map((item: any, i) => {
        return (
          <SC.StyledLink key={i} {...item.linkProps} $active={item.active}>
            <span>{item.linkProps.text}</span>
            <span>({item.count})</span>
          </SC.StyledLink>
        )
      })}
    </SC.Container>
  )
}

export default ListTagLinks
