import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 2rem;
`

export const Button = styled(ActionButton)`
  width: 100%;
`
