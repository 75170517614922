import React, { FC } from 'react'

import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type ActionButtonBlockProps = {
  className?: string
  actionButtonList?: ActionButtonProps[]
}

const ActionButtonBlock: FC<ActionButtonBlockProps> = ({
  className,
  actionButtonList,
}) => {
  return (
    <SC.Container className={className}>
      {actionButtonList?.map((item, i) => {
        return <SC.Button {...item} key={i} />
      })}
    </SC.Container>
  )
}

export default ActionButtonBlock
