import React, { FC } from 'react'

import { IconProps } from '../../atoms/Icon'

import * as SC from './styled'

export type InformationPointsProps = {
  className?: string
  pointsList?: { title: string; pointIcon: IconProps }[]
}

const InformationPoints: FC<InformationPointsProps> = ({
  className,
  pointsList,
}) => {
  return (
    <SC.Container className={className}>
      {pointsList?.map((item, i) => {
        return (
          <SC.Point key={i}>
            <SC.StyledIcon {...item.pointIcon} />
            <SC.PointTitle>{item.title}</SC.PointTitle>
          </SC.Point>
        )
      })}
    </SC.Container>
  )
}

export default InformationPoints
