import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import Icon from '../../atoms/Icon'

export const Container = styled.div``
export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.arapawa};
  padding: 3.3rem 4.9rem;
  border-radius: 2rem 2rem 0 0;
`
export const SectionHeaderTitle = styled.h4`
  text-align: center;
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.pureWhite};

  & span {
    margin-left: 0.9rem;
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.pureWhite};
    padding: 0.2rem 0.8rem;
    border-radius: 1.2rem;
  }
`

export const Texts = styled.div<{ $empty: boolean }>`
  display: flex;
  flex-direction: ${({ $empty }) => ($empty ? 'column' : 'row')};
  align-items: center;
  justify-content: space-between;
  padding: ${({ $empty }) =>
    $empty ? '1.6rem 1rem' : '1.6rem 1rem 2.6rem 1rem'};
  background-color: ${({ $empty, theme }) =>
    $empty ? theme.colors.pureWhite : theme.colors.aliceBlue};
`
export const Text = styled.h4`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.arapawa};
`
export const Rising = styled.h2`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH2}
  color: ${({ theme }) => theme.colors.ruby};
`

export const List = styled.div`
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem 1.6rem 1rem;
`
export const ListItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`
export const TextsContent = styled.div`
  text-align: start;
`
export const ReductionItem = styled.h3`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.ruby};
`
export const TextItem = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.arapawa};
`
export const StyledPicture = styled(Picture)`
  & img {
    max-width: 8rem;
    height: 8rem;
  }
`
export const ItemCloseIcon = styled(Icon)`
  min-width: 2.8rem;
  height: 2.8rem;
  border: 1px solid ${({ theme }) => theme.colors.bismark};
  border-radius: 50%;
  padding: 0.6rem;
  color: ${({ theme }) => theme.colors.bismark};
`

export const SectionFooter = styled.div`
  padding: 2rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.pattensBlue};
  border-radius: 0 0 2rem 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  justify-content: center;
`
