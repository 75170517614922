import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'

export const Banner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;
`

export const BackgroundImage = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  height: 100%;
  width: 100%;

  & img {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }
`
export const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBackgroundImage = styled(BackgroundImage)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
export const Text = styled.h1`
  position: relative;
  z-index: 2;
  border-top: 4px solid ${({ theme }) => theme.colors.bananaMania};
  border-bottom: 4px solid ${({ theme }) => theme.colors.bananaMania};
  padding: 1.5rem 3rem 1rem 3rem;
  text-align: center;
  clip-path: polygon(
    0 0,
    100% 0%,
    calc(100% - 20px) 50%,
    100% 100%,
    0 100%,
    calc(0% + 20px) 50%
  );
  transform: rotate(-2deg);
  ${({ theme }) => theme.textStyles.titleH4};
  color: ${({ theme }) => theme.colors.pureWhite};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.arapawa};
  filter: blur(0.01px);
  // fix the blur on safari
  will-change: transform;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
    padding: 2.8rem 4rem 2rem 4rem;
  }
`
