import styled from 'styled-components'

import Icon from '../../atoms/Icon'
import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 4rem 10rem;
    gap: 2rem;
  }
`
export const Point = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`
export const StyledIcon = styled(Icon)`
  width: 6rem;
  height: 6rem;
  padding: 1.1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 4rem;
  transform: rotate(-4deg);
  color: ${({ theme }) => theme.colors.ruby};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

  @media ${mq(sizes.tablet)} {
    width: 12rem;
    height: 12rem;
    padding: 2.4rem;
  }
`
export const PointTitle = styled.h6`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH8}
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH6}
  }
`
