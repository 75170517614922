import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'

export const Container = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 2rem;
  width: 100%;

  @media ${mq(sizes.tablet)} {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const StyledLink = styled(Link)<{ $active: boolean }>`
  padding: 1.15rem 1.6rem 1rem 1.6rem;
  border-radius: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.greyChateau};
  display: flex;
  align-items: center;
  min-width: max-content;

  & span:first-child {
    ${({ theme }) => theme.textStyles.titleH7}
    color: ${({ theme }) => theme.colors.arapawa};
    padding-right: 1rem;
  }
  & span:last-child {
    ${({ theme }) => theme.textStyles.textSmall}
    color: ${({ theme }) => theme.colors.bismark};
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.arapawa};
      border-color: ${({ theme }) => theme.colors.arapawa};
      & span:first-child {
        color: ${({ theme }) => theme.colors.pureWhite};
      }
      & span:last-child {
        color: ${({ theme }) => theme.colors.pureWhite};
      }
    `}
`
