import React, { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'

import * as SC from './styled'

export type BannerProps = MinimalSeoBlock & {
  imageProps?: PictureProps
  mobileImageProps?: PictureProps
  text?: string
}

const Banner: FC<BannerProps> = ({
  htmlTag,
  imageProps,
  mobileImageProps,
  text,
}) => {
  return (
    <SC.Banner>
      {text && <SC.Text as={htmlTag}>{text}</SC.Text>}
      {!mobileImageProps && imageProps && (
        <SC.BackgroundImage {...imageProps} />
      )}
      {mobileImageProps && imageProps && (
        <>
          <SC.DesktopBackgroundImage {...imageProps} />
          <SC.MobileBackgroundImage {...mobileImageProps} />
        </>
      )}
    </SC.Banner>
  )
}

export default Banner
