import React, { FC } from 'react'

import { Icons } from '../../atoms/Icon'
import { PictureProps } from '../../atoms/Picture'
import ActionButton, { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type CartCouponProps = {
  logo: PictureProps
  reduction: string
  text?: string
  onClick?: () => void
}

export type ReductionsListProps = {
  className?: string
  sectionHeader?: { title: string; count: number }
  texts?: { text: string; rising: string; risingIsNull?: boolean }
  reductionsList?: CartCouponProps[]
  buttonFooter?: ActionButtonProps
}

const ReductionsList: FC<ReductionsListProps> = ({
  className,
  sectionHeader,
  texts,
  reductionsList,
  buttonFooter,
}) => {
  return (
    <SC.Container className={className}>
      {sectionHeader?.title && (
        <SC.SectionHeader>
          <SC.SectionHeaderTitle>
            {sectionHeader?.title}
            {sectionHeader?.count > 0 && <span>{sectionHeader?.count}</span>}
          </SC.SectionHeaderTitle>
        </SC.SectionHeader>
      )}
      {texts && (
        <SC.Texts $empty={texts.risingIsNull ?? false}>
          <SC.Text>{texts.text}</SC.Text>
          <SC.Rising>{texts.rising}</SC.Rising>
        </SC.Texts>
      )}
      {reductionsList && reductionsList.length > 0 && (
        <SC.List>
          {reductionsList.map((item, i) => {
            return (
              <SC.ListItem key={i} onClick={item.onClick}>
                <SC.Content>
                  <SC.StyledPicture {...item.logo} />
                  <SC.TextsContent>
                    {item.reduction && (
                      <SC.ReductionItem>{item.reduction}€</SC.ReductionItem>
                    )}
                    {item.text && <SC.TextItem>{item.text}</SC.TextItem>}
                  </SC.TextsContent>
                </SC.Content>

                <SC.ItemCloseIcon icon={Icons.close} />
              </SC.ListItem>
            )
          })}
        </SC.List>
      )}
      {buttonFooter && (
        <SC.SectionFooter>
          <ActionButton {...buttonFooter} />
        </SC.SectionFooter>
      )}
    </SC.Container>
  )
}

export default ReductionsList
