import React, { FC } from 'react'

import TwoColumnsLayout from '../../../layouts/TwoColumnsLayout'
import InformationPoints, { InformationPointsProps } from '../InformationPoints'
import { CouponCardsBlockProps } from '../CouponCardsBlock'

import * as SC from './styled'

export type CouponsListBlockProps = {
  className?: string
  informationPointsProps?: InformationPointsProps
  sideContent?: React.ReactNode
  cardsBlock?: CouponCardsBlockProps
}

const CouponsListBlock: FC<CouponsListBlockProps> = ({
  className,
  informationPointsProps,
  sideContent,
  cardsBlock,
}) => {
  return (
    <SC.Container className={className}>
      {informationPointsProps && (
        <InformationPoints {...informationPointsProps} />
      )}
      <TwoColumnsLayout
        mainContent={<SC.CardsBlock {...cardsBlock} />}
        sideContent={sideContent}
      />
    </SC.Container>
  )
}

export default CouponsListBlock
