import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import CouponCardsBlock from '../CouponCardsBlock'

export const Container = styled.article``

export const CardsBlock = styled(CouponCardsBlock)`
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 2rem 4rem;
  }
`
